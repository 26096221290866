import React from "react"
import "../pages/mystyles.scss"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const NameHeader = (data) => {
    const image = getImage(data.image)
    return (
        <section className="section hero">
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <GatsbyImage
                            image={image}
                            alt="Rusty Conover"
                            className="image is-128x128" />
                    </div>
                    <div className="level-item">
                        <h1 className="title is-2 has-text-center-mobile">Rusty Conover</h1>
                    </div>
                </div>
            </div>
            <nav className="level is-hidden-mobile">
                <div className="level-left">
                    <div className="level-item">
                        <span className="icon">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <a href="mailto:rusty@conover.me">rusty@conover.me</a>
                    </div>
                    <div className="level-item">
                        <span className="icon">
                            <i className="fas fa-link"></i>
                        </span>
                        <a href="https://rusty.today">https://rusty.today</a>
                    </div>
                </div>
            </nav>


            <div className="buttons has-addons is-centered is-hidden-full-hd is-hidden-tablet">
                <button className="button" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "mailto:rusty@conover.me";
                }}>
                    <span className="icon is-small">
                        <i className="fas fa-envelope"></i>
                    </span>
                    <span>Email</span>
                </button>
                <button className="button" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://rusty.today";
                }}>
                    <span className="icon is-small">
                        <i className="fas fa-link"></i>
                    </span>
                    <span>Blog</span>
                </button>
            </div>
        </section>
    );
}


export default NameHeader;
